import { facadeApi } from 'api'
import { FavouriteLink } from 'types/favouriteLinks/favouriteLink'

export interface Params {
  organisationsId?: string
  userId?: string
}

export type FavouriteLinksResponse = FavouriteLink[]

export const fetchFavouriteLinksApi = ({ organisationsId, userId }: Params) =>
  facadeApi.get<FavouriteLinksResponse>('/apps', {
    params: {
      lang: 'en-US',
      sort: 'name',
      az_account_id: organisationsId,
      user_id: userId,
    },
  })
