import { recentWorkApi } from 'api'
import { RecentWorkItem } from 'types/recentWork/recentWork'

export interface Params {
  userId: string
  parentId?: string
}

export interface RecentWorkResponse {
  data: RecentWorkItem[]
}

export const fetchRecentWorkApi = ({ userId, parentId }: Params) =>
  recentWorkApi.get<RecentWorkResponse>(`/users/${userId}/activity`, {
    params: {
      parent_id: parentId,
    },
  })
