// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NzgV1{overflow-y:auto}.IMeTN+.IMeTN{margin-top:4px}", "",{"version":3,"sources":["webpack://./src/components/widgetList/WidgetList.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CAIA,cACE,cAAA","sourcesContent":[".list {\n  overflow-y: auto;\n}\n\n.listItem {\n  & + & {\n    margin-top: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "NzgV1",
	"listItem": "IMeTN"
};
export default ___CSS_LOADER_EXPORT___;
