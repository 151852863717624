import styles from 'components/dashboardEdit/DashboardEdit.module.scss'

interface Props {
  columnsCount: number
  rowsCount: number
}

export const EmptyLayout = ({ columnsCount, rowsCount }: Props) => (
  <div className={styles.emptyLayout}>
    {Array.from({ length: columnsCount * rowsCount }, (_, idx) => (
      <div
        key={idx}
        className={styles.emptyCell}
        style={{
          gridColumn: (idx % columnsCount) + 1,
          gridRow: Math.floor(idx / columnsCount) + 1,
        }}
      />
    ))}
  </div>
)
