import { Widget } from 'types/widgets/widget'

export const alignWidgetsToLeft = (widgets: Widget[]): Widget[] => {
  // Sort the widgets by their position in the grid (from top-left to bottom-right)
  const sortedWidgets = [...widgets.map(widget => ({ ...widget }))].sort(
    (a, b) => a.gridRowStart - b.gridRowStart || a.gridColumnStart - b.gridColumnStart,
  )

  // Iterate over each widget to align it to the left as much as possible
  sortedWidgets.forEach(widget => {
    // Initialize the target column to the current widget's gridColumnStart - 1
    let targetColumn = widget.gridColumnStart - 1

    // Keep checking for possible leftward shifts until targetColumn reaches 0
    while (targetColumn >= 0) {
      let hasCollision = false

      // Iterate over the rows that the widget occupies
      for (let i = widget.gridRowStart - 1; i < widget.gridRowEnd - 1; i++) {
        // Iterate over the columns that the widget would occupy if shifted to targetColumn
        for (let j = targetColumn; j < widget.gridColumnEnd - 1 - (widget.gridColumnStart - 1 - targetColumn); j++) {
          // Check if the widget would overlap with any other widget when shifted to targetColumn
          const collidingWidget = sortedWidgets.find(
            otherWidget =>
              otherWidget !== widget &&
              otherWidget.gridRowStart - 1 <= i &&
              otherWidget.gridRowEnd - 1 > i &&
              otherWidget.gridColumnStart - 1 <= j &&
              otherWidget.gridColumnEnd - 1 > j,
          )
          // If there's an overlap, set hasCollision to true and break out of the loops
          if (collidingWidget) {
            hasCollision = true
            break
          }
        }
        if (hasCollision) {
          break
        }
      }

      // If there's no collision, update the widget's gridColumnStart and gridColumnEnd
      if (!hasCollision) {
        const shiftAmount = widget.gridColumnStart - 1 - targetColumn
        widget.gridColumnStart -= shiftAmount
        widget.gridColumnEnd -= shiftAmount
      } else {
        break
      }

      // Move to the next possible targetColumn on the left
      targetColumn--
    }
  })

  // Return the updated widgets array
  return sortedWidgets
}
