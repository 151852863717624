export enum ApiQueryKeys {
  FAVOURITE_LINKS = 'favourite-links',
  RECENT_WORK = 'recent-work',
  RECENT_CLIENTS = 'recent-clients',
  NEWS = 'news',
  PROJECTS = 'projects',
  WORKFLOW_TEMPLATES = 'workflow-templates',
  PAGES = 'pages',
  WIDGETS = 'widgets',
}
