// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aMoJG{display:flex;flex:1 1;flex-direction:column;gap:16px;align-items:center;justify-content:center}.ZU1Vd{max-width:235px;color:var(--wpp-grey-color-700);text-align:center}.seAHr{z-index:2}", "",{"version":3,"sources":["webpack://./src/components/widgetEmptyState/WidgetEmptyState.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,QAAA,CACA,qBAAA,CACA,QAAA,CACA,kBAAA,CACA,sBAAA,CAGF,OACE,eAAA,CACA,+BAAA,CACA,iBAAA,CAGF,OACE,SAAA","sourcesContent":[".root {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  gap: 16px;\n  align-items: center;\n  justify-content: center;\n}\n\n.textContainer {\n  max-width: 235px;\n  color: var(--wpp-grey-color-700);\n  text-align: center;\n}\n\n.actionButton {\n  z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "aMoJG",
	"textContainer": "ZU1Vd",
	"actionButton": "seAHr"
};
export default ___CSS_LOADER_EXPORT___;
