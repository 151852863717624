import {
  WppCard,
  WppIconPlusCircle,
  WppIconRemoveCircle,
  WppInput,
  WppTag,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useSetState } from 'react-use'

import styles from 'components/addRemoveWidgets/AddRemoveWidget.module.scss'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { Delay } from 'constants/delay'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { Widget, WidgetType } from 'types/widgets/widget'

interface Props {
  isOpen: boolean
  onClose: () => void
  widgets: Widget[]
  addWidget: (id: string) => void
  removeWidget: (id: string) => void
}

interface State {
  search: string
}

export const AddRemoveWidgets = ({ widgets, onClose, isOpen, addWidget, removeWidget }: Props) => {
  const [{ search }, setState] = useSetState<State>({
    search: '',
  })

  const setSearchDebounced = useDebounceFn(
    (search?: string) => setState({ search: search?.trim().toLowerCase() || '' }),
    Delay.Search,
  )

  const widgetTag = (type: WidgetType) => {
    switch (type) {
      case WidgetType.PREDEFINED:
        return 'System'
    }
  }

  return (
    <SideModal
      open={isOpen}
      formConfig={{
        onSubmit: () => {},
      }}
      size="s"
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={() => {}}
      disableOutsideClick
    >
      <WppTypography slot="header" type="2xl-heading">
        Add widgets
      </WppTypography>
      <div slot="body">
        <WppInput
          size="m"
          type="search"
          className={styles.searchInput}
          onWppChange={({ detail }) => setSearchDebounced(detail.value)}
          placeholder="Search widgets"
        />
        <div>
          {widgets
            .filter(({ title }) => title.toLowerCase().includes(search))
            .sort((a, b) => {
              if (a.title < b.title) {
                return -1
              }
              if (a.title > b.title) {
                return 1
              }
              return 0
            })
            .map(widget => (
              <WppCard className={styles.widget} size="m" key={widget.id}>
                <div className={styles.header}>
                  <WppTypography type="m-strong">{widget.title}</WppTypography>
                  {widget.enabled ? (
                    <WppIconRemoveCircle className={styles.icon} onClick={() => removeWidget(widget.id)} />
                  ) : (
                    <WppIconPlusCircle
                      className={clsx([styles.add, styles.icon])}
                      onClick={() => addWidget(widget.id)}
                    />
                  )}
                </div>
                <WppTag label={widgetTag(widget.type)} variant="neutral" />
              </WppCard>
            ))}
        </div>
      </div>
    </SideModal>
  )
}
