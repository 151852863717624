// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fzQ65{height:100%}.fzQ65::part(card){display:flex;flex-direction:column}.fzQ65::part(header-wrapper){padding-left:8px}", "",{"version":3,"sources":["webpack://./src/components/widgetCard/WidgetCard.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CAEA,mBACE,YAAA,CACA,qBAAA,CAGF,6BACE,gBAAA","sourcesContent":[".root {\n  height: 100%;\n\n  &::part(card) {\n    display: flex;\n    flex-direction: column;\n  }\n\n  &::part(header-wrapper) {\n    padding-left: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "fzQ65"
};
export default ___CSS_LOADER_EXPORT___;
