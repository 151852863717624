import { useOs } from '@wpp-open/react'
import { useCallback } from 'react'

import { Permission } from 'constants/permission'
import { hasPermission as checkPermission } from 'utils/permissions'

export const useHasPermission = () => {
  const { osContext } = useOs()

  const { permissions, tenant } = osContext

  const hasPermission = useCallback(
    (permission?: string) => !permission || checkPermission(permissions, permission, tenant.azMeta.organizationsId),
    [permissions, tenant.azMeta.organizationsId],
  )

  return {
    hasPermission,
    hasAccessToManageWidgets: hasPermission(Permission.OsWidgetsEntitiesManage),
  }
}
