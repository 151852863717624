// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".n3p1N{margin-bottom:16px}.Shw3Z{height:82px;margin-bottom:16px}.C6J2L{color:var(--wpp-primary-color-500)}.quY7z{cursor:pointer}.U0dOr{display:flex;align-items:center;justify-content:space-between;margin-bottom:6px}", "",{"version":3,"sources":["webpack://./src/components/addRemoveWidgets/AddRemoveWidget.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CAGF,OACE,WAAA,CACA,kBAAA,CAGF,OACE,kCAAA,CAGF,OACE,cAAA,CAGF,OACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,iBAAA","sourcesContent":[".searchInput {\n  margin-bottom: 16px;\n}\n\n.widget {\n  height: 82px;\n  margin-bottom: 16px;\n}\n\n.add {\n  color: var(--wpp-primary-color-500);\n}\n\n.icon {\n  cursor: pointer;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInput": "n3p1N",
	"widget": "Shw3Z",
	"add": "C6J2L",
	"icon": "quY7z",
	"header": "U0dOr"
};
export default ___CSS_LOADER_EXPORT___;
