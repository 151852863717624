import { useOs } from '@wpp-open/react'
import { useEffect, PropsWithChildren, useState } from 'react'

import { recentWorkApi, newsApi, facadeApi, projectsApi } from 'api'

const apiInstances = [recentWorkApi, newsApi, projectsApi, facadeApi]

export const ApiProvider = ({ children }: PropsWithChildren<{}>) => {
  const { osApi } = useOs()
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    apiInstances.forEach(instance => {
      instance.client.interceptors.request.use(
        config => {
          config!.headers!.Authorization = `Bearer ${osApi.getAccessToken()}`

          return config
        },
        error => Promise.reject(error),
      )

      instance.client.interceptors.response.use(
        response => response,
        error => Promise.reject(error),
      )
    })

    setIsReady(true)
  }, [osApi])

  return <>{isReady && <>{children}</>}</>
}
