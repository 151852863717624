// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ybGQZ{position:relative;z-index:1}.YDgCm{border-radius:8px;box-shadow:0 0 0 1px var(--wpp-grey-color-1000);--wpp-fb-width: 24px;--wpp-fb-heigth: 24px}.l94Nw{position:absolute;z-index:2;width:100%;height:100%}.LTBsi{position:absolute;top:calc(50% - 12px);left:0;z-index:2;margin-left:-12px}.qfb9r{position:absolute;top:calc(50% - 12px);right:0;z-index:2;margin-right:-12px}.xdN9_{position:absolute;top:0;left:calc(50% - 12px);z-index:2;margin-top:-12px}.F4_RJ{position:absolute;bottom:0;left:calc(50% - 12px);z-index:2;margin-bottom:-12px}", "",{"version":3,"sources":["webpack://./src/components/dashboardEditMode/DashboardEditMode.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,SAAA,CAGF,OACE,iBAAA,CACA,+CAAA,CACA,oBAAA,CACA,qBAAA,CAGF,OACE,iBAAA,CACA,SAAA,CACA,UAAA,CACA,WAAA,CAGF,OACE,iBAAA,CACA,oBAAA,CACA,MAAA,CACA,SAAA,CACA,iBAAA,CAGF,OACE,iBAAA,CACA,oBAAA,CACA,OAAA,CACA,SAAA,CACA,kBAAA,CAGF,OACE,iBAAA,CACA,KAAA,CACA,qBAAA,CACA,SAAA,CACA,gBAAA,CAGF,OACE,iBAAA,CACA,QAAA,CACA,qBAAA,CACA,SAAA,CACA,mBAAA","sourcesContent":[".item {\n  position: relative;\n  z-index: 1;\n}\n\n.selected {\n  border-radius: 8px;\n  box-shadow: 0 0 0 1px var(--wpp-grey-color-1000);\n  --wpp-fb-width: 24px;\n  --wpp-fb-heigth: 24px;\n}\n\n.cover {\n  position: absolute;\n  z-index: 2;\n  width: 100%;\n  height: 100%;\n}\n\n.left {\n  position: absolute;\n  top: calc(50% - 12px);\n  left: 0;\n  z-index: 2;\n  margin-left: -12px;\n}\n\n.right {\n  position: absolute;\n  top: calc(50% - 12px);\n  right: 0;\n  z-index: 2;\n  margin-right: -12px;\n}\n\n.top {\n  position: absolute;\n  top: 0;\n  left: calc(50% - 12px);\n  z-index: 2;\n  margin-top: -12px;\n}\n\n.down {\n  position: absolute;\n  bottom: 0;\n  left: calc(50% - 12px);\n  z-index: 2;\n  margin-bottom: -12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "ybGQZ",
	"selected": "YDgCm",
	"cover": "l94Nw",
	"left": "LTBsi",
	"right": "qfb9r",
	"top": "xdN9_",
	"down": "F4_RJ"
};
export default ___CSS_LOADER_EXPORT___;
