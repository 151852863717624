import { WppTypography, WppIconChevron } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'

import { useRecentWorkApi } from 'api/recentWork/queries/useRecentWorkApi'
import styles from 'components/recentWorkWidget/RecentWorkWidget.module.scss'
import { WidgetCard } from 'components/widgetCard/WidgetCard'
import { WidgetEmptyState } from 'components/widgetEmptyState/WidgetEmptyState'
import { WidgetHeaderActions } from 'components/widgetHeaderActions/WidgetHeaderActions'
import { WidgetList } from 'components/widgetList/WidgetList'
import { WidgetListItem } from 'components/widgetListItem/WidgetListItem'
import { useHasPermission } from 'hooks/useHasPermissions'
import { RecentWorkItem } from 'types/recentWork/recentWork'

interface Props {
  isEditMode?: boolean
  onRemove?: () => void
}

export const RecentWorkWidget = ({ isEditMode, onRemove }: Props) => {
  const { osContext } = useOs()
  const { tenant, userDetails } = osContext
  const { hasAccessToManageWidgets } = useHasPermission()

  const {
    data: recentWork,
    error,
    isLoading,
  } = useRecentWorkApi({
    params: {
      userId: userDetails.id,
      parentId: tenant.azId,
    },
  })

  const generateLink = ({ brand, application, url }: RecentWorkItem) => {
    switch (application.name) {
      case 'Audiences':
        return `/brands/${brand.id}/audiences${url}`
      case 'Campaign creator':
        return `/brands/${brand.id}/briefs`
      case 'Media Plans Comparison':
        return `/brands/${brand.id}/mp-comparison`
      default:
        return ''
    }
  }

  return (
    <WidgetCard data-testid="recent-work-widget">
      <WppTypography slot="header" type="m-strong">
        Recent Work
      </WppTypography>
      <WidgetHeaderActions testId="recent-work" isEditMode={isEditMode} onRemove={onRemove} />

      {!recentWork.length ? (
        <WidgetEmptyState
          isLoading={isLoading}
          text={
            hasAccessToManageWidgets
              ? "Recent member's work within apps will be displayed here"
              : 'No Recent work found'
          }
          testId="recent-work"
          {...(error && { errorText: 'Unable to display Recent Work' })}
        />
      ) : (
        <WidgetList
          data-testid="recent-work-list"
          items={recentWork}
          renderItem={recentWorkItem => {
            const { advertiser, market, brand, application, project } = recentWorkItem
            const currentAppSpace = [advertiser.name, market.name, brand.name].join(' / ')

            const href = generateLink(recentWorkItem)

            return (
              <WidgetListItem
                data-testid={`recent-work-${currentAppSpace}`}
                className={styles.listItem}
                linkConfig={{
                  href,
                  onClick: e => {
                    e.preventDefault()
                    window.history.pushState({}, '', href)
                  },
                }}
              >
                <div slot="label" className={styles.activityContent}>
                  <WppTypography type="s-midi" data-testid={application.name}>
                    {application.name}
                  </WppTypography>
                  <WppTypography type="s-body" data-testid={project.name}>
                    {project.name}
                  </WppTypography>
                  <WppTypography className={styles.workspaceText} type="xs-body">
                    {currentAppSpace}
                  </WppTypography>
                </div>

                <WppIconChevron slot="right" className={styles.chevron} direction="right" />
              </WidgetListItem>
            )
          }}
        />
      )}
    </WidgetCard>
  )
}
