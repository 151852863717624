import {
  WppIconCalendar3Days,
  WppIconFile,
  WppIconPeople,
  WppIconVideoClip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { clsx } from 'clsx'
import { ComponentPropsWithoutRef } from 'react'

import styles from 'components/projectsWidget/projectTypeTag/ProjectTypeTag.module.scss'
import { ProjectType } from 'types/project/project'

type Props = ComponentPropsWithoutRef<'div'> & {
  type: ProjectType
}

const projectTypePreset = {
  [ProjectType.DRAFT]: {
    value: ProjectType.DRAFT,
    icon: <WppIconFile color="var(--wpp-dataviz-color-cat-dark-1)" />,
    title: 'Draft',
  },
  [ProjectType.PITCH]: {
    value: ProjectType.PITCH,
    icon: <WppIconVideoClip color="var(--wpp-dataviz-color-cat-dark-3)" />,
    title: 'Pitch',
  },
  [ProjectType.WORKSHOP]: {
    value: ProjectType.WORKSHOP,
    icon: <WppIconPeople color="var(--wpp-dataviz-color-cat-dark-5)" />,
    title: 'Workshop',
  },
  [ProjectType.CAMPAIGN]: {
    value: ProjectType.CAMPAIGN,
    icon: <WppIconCalendar3Days color="var(--wpp-dataviz-color-cat-dark-7)" />,
    title: 'Campaign',
  },
}

export const ProjectTypeTag = ({ type, className, ...rest }: Props) => {
  const { icon, title } = projectTypePreset[type] || {}

  return (
    <div {...rest} className={clsx(styles.tag, styles[type], className)}>
      {icon}
      <WppTypography type="s-midi" className={styles[type]}>
        {title}
      </WppTypography>
    </div>
  )
}
