import { createUseQuery } from 'api/common/createUseQuery'
import { fetchNewsApi } from 'api/news/fetchers/fetchNewsApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useNewsApi = createUseQuery({
  queryKey: ApiQueryKeys.NEWS,
  fetcher: fetchNewsApi,
  selector: res => {
    // TODO Remove this hardcode!!!
    const titles = [
      'OS Release - Get ready to experience the upgraded collaboration and innovation platform.',
      'OS Release - Join the WPP Open Team for Demos and Office Hours',
    ]

    return res?.data?.filter(newItem => titles.includes(newItem.title)) || []
  },
})
