import {
  WppActionButton,
  WppButton,
  WppIconPlus,
  WppSpinner,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useEffect, useMemo } from 'react'
import { useSetState } from 'react-use'

import { useCreatePageApi } from 'api/widgets/mutations/useCreatePageApi'
import { useUpdatePageApi } from 'api/widgets/mutations/useUpdatePageApi'
import { usePagesApi } from 'api/widgets/queries/usePagesApi'
import styles from 'components/dashboardEdit/DashboardEdit.module.scss'
import { EmptyState } from 'components/dashboardEdit/EmptyState'
import { DashboardEditMode } from 'components/dashboardEditMode/DashboardEditMode'
import { RenderWidget } from 'components/renderWidget/RenderWidget'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermissions'
import { useToast } from 'hooks/useToast'
import { Page, PageAddUpdateDTO } from 'types/widgets/page'
import { Widget, EmptyWidgetType, DashboardWidget } from 'types/widgets/widget'

interface State {
  widgets: Widget[]
  defaultPage: boolean
  pageId: string
  editMode: boolean
  widgetsToSave: Widget[]
  addRemoveWidgetModalOpen: boolean
}

export const DashboardEdit = () => {
  const { enqueueToast } = useToast()
  const { osContext } = useOs()
  const { tenant } = osContext
  const { hasPermission } = useHasPermission()

  const hasAccessToManageWidgets = hasPermission(Permission.OsWidgetsEntitiesManage)

  const [{ widgets, defaultPage, pageId, editMode, widgetsToSave, addRemoveWidgetModalOpen }, setState] =
    useSetState<State>({
      widgets: [],
      defaultPage: true,
      pageId: '',
      editMode: false,
      widgetsToSave: [],
      addRemoveWidgetModalOpen: false,
    })

  const queryClient = useQueryClient()
  const { isLoading, data } = usePagesApi({
    params: {
      tenantId: tenant.id,
    },
  })

  const page = useMemo<MayBeNull<Page>>(() => {
    if (!data.length) {
      return null
    }

    return data.length === 1 ? data[0] : data.find((page: Page) => !page.default)!
  }, [data])

  useEffect(() => {
    if (page) {
      setState({ widgets: page.widgets, pageId: page.id, defaultPage: page.default, widgetsToSave: page.widgets })
    }
  }, [page, setState])

  const { mutateAsync: handleCreatePage } = useCreatePageApi()
  const { mutateAsync: handleUpdatePage } = useUpdatePageApi()

  const onCreateSubmit = async (page: PageAddUpdateDTO) => {
    try {
      await handleCreatePage({
        organizationId: tenant.azMeta.organizationsId,
        page,
      })

      await queryClient.invalidateQueries([ApiQueryKeys.PAGES])

      enqueueToast({ message: 'The homepage is updated successfully', type: 'success' })
      setState({ editMode: false })
    } catch (e) {
      enqueueToast({
        message: 'Something went wrong, please try again',
        type: 'error',
      })
    }
  }

  const onUpdateSubmit = async (page: PageAddUpdateDTO, pageId: string) => {
    try {
      await handleUpdatePage({
        organizationId: tenant.azMeta.organizationsId,
        page,
        pageId,
      })

      await queryClient.invalidateQueries([ApiQueryKeys.PAGES])

      enqueueToast({ message: 'The homepage is updated successfully', type: 'success' })
      setState({ editMode: false })
    } catch (e) {
      enqueueToast({
        message: 'Something went wrong, please try again',
        type: 'error',
      })
    }
  }

  const onSave = () => {
    const page = {
      name: 'HOME_PAGE',
      tenantId: tenant.id,
      widgets: widgetsToSave,
      default: false,
    }
    if (defaultPage) {
      return onCreateSubmit(page)
    } else {
      return onUpdateSubmit(page, pageId)
    }
  }

  const onCancel = () => {
    setState({ editMode: false, widgetsToSave: widgets })
  }

  const setWidgetsToSave = (widgets: DashboardWidget[]) => {
    setState({ widgetsToSave: widgets.filter(({ type }) => type !== EmptyWidgetType.EMPTY) as Widget[] })
  }

  const filteredWidgets = widgets.filter(({ enabled }) => enabled)
  const showEditButton = hasAccessToManageWidgets && !editMode

  return isLoading ? (
    <div className={styles.loading}>
      <WppSpinner size="l" />
    </div>
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <WppTypography type="3xl-heading" data-testid="admin-homepage-title">
          {editMode ? 'Edit Homepage' : defaultPage ? 'Homepage' : `${tenant.name} Homepage`}
        </WppTypography>
        <div className={styles.actions}>
          {editMode && (
            <>
              <WppActionButton
                data-testid="admin-homepage-add-widget"
                onClick={() => {
                  setState({ addRemoveWidgetModalOpen: true })
                }}
              >
                <WppIconPlus slot="icon-start" />
                Add widget
              </WppActionButton>
              <WppButton variant="secondary" onClick={onCancel} data-testid="admin-homepage-edit-cancel">
                Cancel
              </WppButton>
              <WppButton onClick={onSave} data-testid="admin-homepage-edit-save">
                Save
              </WppButton>
            </>
          )}
          {showEditButton && (
            <WppButton onClick={() => setState({ editMode: true })} data-testid="admin-homepage-edit-button">
              Edit Homepage
            </WppButton>
          )}
        </div>
      </div>

      {editMode ? (
        <div className={styles.container}>
          <DashboardEditMode
            widgets={widgets}
            setWidgetsToSave={setWidgetsToSave}
            addRemoveWidgetsModalOpen={addRemoveWidgetModalOpen}
            addRemoveWidgetsModalOnClose={() => {
              setState({ addRemoveWidgetModalOpen: false })
            }}
          />
        </div>
      ) : filteredWidgets.length ? (
        <div className={styles.container}>
          {filteredWidgets.map(({ id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name }) => (
            <div
              className={styles.item}
              id={id}
              key={id}
              style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}
            >
              <RenderWidget type={name} />
            </div>
          ))}
        </div>
      ) : hasAccessToManageWidgets ? (
        <EmptyState text="The Homepage is empty. Start adding widgets now" />
      ) : (
        <EmptyState text={'The Homepage is empty. \n Widgets will be displayed here once your Tenant Admin add them'} />
      )}
    </div>
  )
}
