import { facadeApi } from 'api'
import { Page } from 'types/widgets/page'

export interface Params {
  tenantId: string
}

export interface Response {
  pages: Page[]
}

export const fetchPagesApi = ({ tenantId }: Params) =>
  facadeApi.get<Response>('/pages', {
    params: {
      tenantId: tenantId,
    },
  })
