import { WppTypography } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'

import { useRecentClientsApi } from 'api/recentClients/queries/useRecentClientsApi'
import { Avatar } from 'components/avatar/Avatar'
import styles from 'components/recentClientsWidget/RecentClientsWidget.module.scss'
import { WidgetCard } from 'components/widgetCard/WidgetCard'
import { WidgetEmptyState } from 'components/widgetEmptyState/WidgetEmptyState'
import { WidgetHeaderActions } from 'components/widgetHeaderActions/WidgetHeaderActions'
import { WidgetList } from 'components/widgetList/WidgetList'
import { WidgetListItem } from 'components/widgetListItem/WidgetListItem'
import { useHasPermission } from 'hooks/useHasPermissions'

interface Props {
  isEditMode?: boolean
  onRemove?: () => void
}

export const RecentClientsWidget = ({ isEditMode, onRemove }: Props) => {
  const { osContext, osApi } = useOs()
  const { tenant, userDetails } = osContext
  const { hasAccessToManageWidgets } = useHasPermission()

  const {
    isLoading,
    error,
    data: recentClients,
  } = useRecentClientsApi({
    params: {
      userId: userDetails.id,
      parentId: tenant.id,
    },
  })

  return (
    <WidgetCard data-testid="recent-clients-widget">
      <WppTypography slot="header" type="m-strong">
        Clients
      </WppTypography>
      <WidgetHeaderActions
        viewAllAction={() => osApi.navigation.openMenu()}
        testId="recent-clients"
        isEditMode={isEditMode}
        onRemove={onRemove}
      />
      {!recentClients.length ? (
        <WidgetEmptyState
          isLoading={isLoading}
          text={hasAccessToManageWidgets ? 'Recently opened clients will be displayed here' : 'No Clients found'}
          testId="recent-clients"
          {...(error && { errorText: 'Unable to display Clients' })}
        />
      ) : (
        <WidgetList
          data-testid="recent-clients-list"
          items={recentClients}
          renderItem={({ id, name, logo_url }) => (
            <WidgetListItem
              className={styles.listItem}
              onWppChangeListItem={() => {
                osApi.analytics.track({
                  type: AnalyticsActionType.action,
                  payload: `Open "${name}" Client`,
                })
                osApi.navigation.openMenu(id)
              }}
            >
              <Avatar slot="left" src={logo_url} name={name} />
              <WppTypography slot="label" title={name} type="s-body" data-testid={`client-${id}`}>
                {name}
              </WppTypography>
            </WidgetListItem>
          )}
        />
      )}
    </WidgetCard>
  )
}
