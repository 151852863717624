export interface Project {
  id: string
  name: string
  description?: string
  contextWorkspace?: string
  type: ProjectType
  startDate?: string
  endDate?: string
  status: ProjectStatus
  ownerId: string
}

export enum ProjectType {
  DRAFT = 'DRAFT',
  PITCH = 'PITCH',
  CAMPAIGN = 'CAMPAIGN',
  WORKSHOP = 'WORKSHOP',
}

export enum ProjectStatus {
  COMPLETED = 'COMPLETED',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum ProjectOwnership {
  ALL = 'ALL',
  MY = 'MY',
  SHARED_WITH_ME = 'SHARED_WITH_ME',
  MEMBER = 'MEMBER',
}
