import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useEffect } from 'react'

import styles from 'app/App.module.scss'
import { DashboardEdit } from 'components/dashboardEdit/DashboardEdit'
import { ToastProvider } from 'providers/toast/ToastProvider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

export const App = () => {
  const { osApi } = useOs()

  useEffect(() => {
    osApi.analytics.track({
      type: AnalyticsActionType.page,
      payload: 'New you page',
    })
  }, [osApi])

  return (
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <div
          className={styles.root}
          data-testid="app-home-root"
          data-build={process.env.REACT_APP_BUILD_NUMBER}
          data-build-branch={process.env.REACT_APP_BRANCH}
          data-commit={process.env.REACT_APP_COMMIT_SHA}
        >
          <DashboardEdit />
        </div>
      </ToastProvider>
    </QueryClientProvider>
  )
}
