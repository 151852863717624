// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wdB5K{display:inline-flex;gap:6px;align-items:center;padding:1px 8px;border-radius:var(--wpp-border-radius-xs)}.Qe4Vy{color:var(--wpp-dataviz-color-cat-dark-1);background:var(--wpp-dataviz-color-cat-light-1)}.Jzfsb{color:var(--wpp-dataviz-color-cat-dark-3);background:var(--wpp-dataviz-color-cat-light-3)}.GEqaE{color:var(--wpp-dataviz-color-cat-dark-5);background:var(--wpp-dataviz-color-cat-light-5)}.hNyaG{color:var(--wpp-dataviz-color-cat-dark-7);background:var(--wpp-dataviz-color-cat-light-7)}", "",{"version":3,"sources":["webpack://./src/components/projectsWidget/projectTypeTag/ProjectTypeTag.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA,CACA,OAAA,CACA,kBAAA,CACA,eAAA,CACA,yCAAA,CAGF,OACE,yCAAA,CACA,+CAAA,CAGF,OACE,yCAAA,CACA,+CAAA,CAGF,OACE,yCAAA,CACA,+CAAA,CAGF,OACE,yCAAA,CACA,+CAAA","sourcesContent":[".tag {\n  display: inline-flex;\n  gap: 6px;\n  align-items: center;\n  padding: 1px 8px;\n  border-radius: var(--wpp-border-radius-xs);\n}\n\n.DRAFT {\n  color: var(--wpp-dataviz-color-cat-dark-1);\n  background: var(--wpp-dataviz-color-cat-light-1);\n}\n\n.PITCH {\n  color: var(--wpp-dataviz-color-cat-dark-3);\n  background: var(--wpp-dataviz-color-cat-light-3);\n}\n\n.WORKSHOP {\n  color: var(--wpp-dataviz-color-cat-dark-5);\n  background: var(--wpp-dataviz-color-cat-light-5);\n}\n\n.CAMPAIGN {\n  color: var(--wpp-dataviz-color-cat-dark-7);\n  background: var(--wpp-dataviz-color-cat-light-7);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": "wdB5K",
	"DRAFT": "Qe4Vy",
	"PITCH": "Jzfsb",
	"WORKSHOP": "GEqaE",
	"CAMPAIGN": "hNyaG"
};
export default ___CSS_LOADER_EXPORT___;
