export interface WidgetAddUpdateDTO {
  type: WidgetType
  enabled: boolean
  pageId?: string
  leanAppId?: string
  name?: SystemWidget
}

export interface Widget {
  id: string
  type: WidgetType
  enabled: boolean
  pageId?: string
  gridColumnStart: number
  gridColumnEnd: number
  gridRowStart: number
  gridRowEnd: number
  createdAt?: string
  updatedAt?: string
  leanAppId?: string
  name?: SystemWidget
  title: string
}

export type EmptyWidget = Omit<Widget, 'type'> & { type: EmptyWidgetType }

export enum WidgetType {
  PREDEFINED = 'PREDEFINED',
}

export enum EmptyWidgetType {
  EMPTY = 'EMPTY',
}

export type DashboardWidget = Widget | EmptyWidget

export enum SystemWidget {
  NEWS = 'NEWS',
  FAVOURITE_LINKS = 'FAVOURITE_LINKS',
  RECENT_WORK = 'RECENT_WORK',
  RECENT_CLIENTS = 'RECENT_CLIENTS',
  PROJECTS = 'PROJECTS',
  PROJECT_TEMPLATES = 'PROJECT_TEMPLATES',
}
