// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pfi5I{display:flex;flex-direction:column}.iRjVt{--wpp-iconography-color: var(--wpp-grey-color-400)}.YbtIT{--wpp-typography-color: var(--wpp-grey-color-800)}.ozSaq{--wpp-list-item-height: auto}", "",{"version":3,"sources":["webpack://./src/components/recentWorkWidget/RecentWorkWidget.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,qBAAA,CAGF,OACE,kDAAA,CAGF,OACE,iDAAA,CAGF,OACE,4BAAA","sourcesContent":[".activityContent {\n  display: flex;\n  flex-direction: column;\n}\n\n.chevron {\n  --wpp-iconography-color: var(--wpp-grey-color-400);\n}\n\n.workspaceText {\n  --wpp-typography-color: var(--wpp-grey-color-800);\n}\n\n.listItem {\n  --wpp-list-item-height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activityContent": "Pfi5I",
	"chevron": "iRjVt",
	"workspaceText": "YbtIT",
	"listItem": "ozSaq"
};
export default ___CSS_LOADER_EXPORT___;
