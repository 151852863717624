import { newsApi } from 'api'
import { NewsPiece } from 'types/news/news'

export interface Params {
  tenantId: string
}

export const fetchNewsApi = ({ tenantId }: Params) =>
  newsApi.get<NewsPiece[]>('/news', {
    params: {
      tenant_id: tenantId,
    },
  })
