// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YN6rE{--wpp-list-item-left-wrapper-margin-right: 12px}", "",{"version":3,"sources":["webpack://./src/components/recentClientsWidget/RecentClientsWidget.module.scss"],"names":[],"mappings":"AAAA,OACE,+CAAA","sourcesContent":[".listItem {\n  --wpp-list-item-left-wrapper-margin-right: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": "YN6rE"
};
export default ___CSS_LOADER_EXPORT___;
