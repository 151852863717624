import { MicroAppCustomProps } from '@wpp-open/core'
import { OsProvider } from '@wpp-open/react'
import { PropsWithChildren, StrictMode } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { ApiProvider } from 'providers/ApiProvider'

export const Root = ({ children, ...rest }: PropsWithChildren<MicroAppCustomProps>) => {
  return (
    <StrictMode>
      <OsProvider {...rest}>
        <ApiProvider>
          <BrowserRouter>{children}</BrowserRouter>
        </ApiProvider>
      </OsProvider>
    </StrictMode>
  )
}
