import { WppTypography } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'

import { updateFavouriteLinkLastUsedApi } from 'api/favouriteLinks/fetchers/updateFavouriteLinkLastUsedApi'
import { useFavouriteLinksApi } from 'api/favouriteLinks/queries/useFavouriteLinksApi'
import { Avatar } from 'components/avatar/Avatar'
import styles from 'components/favouriteLinksWidget/FavouriteLinksWidget.module.scss'
import { WidgetCard } from 'components/widgetCard/WidgetCard'
import { WidgetEmptyState } from 'components/widgetEmptyState/WidgetEmptyState'
import { WidgetHeaderActions } from 'components/widgetHeaderActions/WidgetHeaderActions'
import { WidgetList } from 'components/widgetList/WidgetList'
import { WidgetListItem } from 'components/widgetListItem/WidgetListItem'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermissions'
import { FavouriteLinkStatus, FavouriteLink } from 'types/favouriteLinks/favouriteLink'

interface Props {
  isEditMode?: boolean
  onRemove?: () => void
}

export const FavouriteLinksWidget = ({ isEditMode, onRemove }: Props) => {
  const { osContext, osApi } = useOs()
  const { hasPermission, hasAccessToManageWidgets } = useHasPermission()

  const { tenant, userDetails } = osContext
  const hasAccess = hasPermission(Permission.OsAppsAccess)

  const {
    data: links,
    error,
    isLoading,
  } = useFavouriteLinksApi({
    params: {
      organisationsId: tenant.azMeta.organizationsId,
      userId: userDetails.id,
    },
    enabled: hasAccess,
  })

  const handleLinkClick = ({ id, name }: FavouriteLink) => {
    updateFavouriteLinkLastUsedApi({
      id,
      organisationsId: tenant.azMeta.organizationsId,
      userId: userDetails.id,
    })

    osApi.analytics.track({
      type: AnalyticsActionType.action,
      payload: `Exit to the "${name}" application`,
    })
  }

  const favouriteLinks = links.filter(
    ({ pinned, favourite, status }) => status !== FavouriteLinkStatus.INACTIVE && (pinned || favourite),
  )

  const viewAllLink = error ? '' : '/links'

  return (
    <WidgetCard data-testid="favourite-links-widget">
      <WppTypography slot="header" type="m-strong" data-testid="favourite-links-title">
        Favourite Links
      </WppTypography>

      {hasAccess && (
        /*
          TODO: Find a better approach without hardcoding microapp url.
          And either remove react-router-dom or use it correctly
          with a basename provided by the Root app.
        */
        <WidgetHeaderActions
          viewAllLink={viewAllLink}
          testId="favourite-links"
          isEditMode={isEditMode}
          onRemove={onRemove}
        />
      )}

      {!favouriteLinks.length ? (
        <WidgetEmptyState
          isLoading={isLoading}
          text={
            hasAccessToManageWidgets
              ? 'External links that were marked as favourites will be displayed here'
              : 'No Links found'
          }
          errorText={hasAccess ? (error ? 'Unable to display Links' : '') : 'No permissions'}
          testId="favourite-links"
          actionButton={hasAccessToManageWidgets && { title: 'Add a link', link: '/links' }}
        />
      ) : (
        <WidgetList
          data-testid="favourite-links-list"
          items={favouriteLinks}
          renderItem={link => {
            const { url, image_url, name, id } = link

            return (
              <WidgetListItem
                data-testid={`favourite-${id}`}
                className={styles.listItem}
                onWppChangeListItem={() => handleLinkClick(link)}
                linkConfig={{
                  href: url,
                  target: '_blank',
                  rel: 'noreferrer',
                }}
              >
                <Avatar slot="left" src={image_url || ''} name={name} />
                <WppTypography slot="label" type="s-body">
                  {name}
                </WppTypography>
              </WidgetListItem>
            )
          }}
        />
      )}
    </WidgetCard>
  )
}
