import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'components/dashboardEdit/DashboardEdit.module.scss'
import { SvgEmptyList } from 'components/svg/SvgEmptyList'

interface Props {
  text: string
}

export const EmptyState = ({ text }: Props) => (
  <div className={styles.emptyState}>
    <SvgEmptyList />
    <WppTypography className={styles.emptyStateText} type="m-strong" data-testid="dashboard-empty-message">
      {text}
    </WppTypography>
  </div>
)
