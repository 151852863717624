import { WppFloatingButton, WppIconChevron, WppTooltip } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { useSetState } from 'react-use'

import { AddRemoveWidgets } from 'components/addRemoveWidgets/AddRemoveWidget'
import { EmptyLayout } from 'components/dashboardEdit/EmptyLayout'
import { alignWidgetsToLeft } from 'components/dashboardEdit/helper'
import styles from 'components/dashboardEditMode/DashboardEditMode.module.scss'
import { RenderWidget } from 'components/renderWidget/RenderWidget'
import { DashboardWidget, EmptyWidgetType, Widget } from 'types/widgets/widget'

interface Props {
  widgets: Widget[]
  setWidgetsToSave: (widgets: DashboardWidget[]) => void
  addRemoveWidgetsModalOpen: boolean
  addRemoveWidgetsModalOnClose: () => void
}

interface State {
  selected: MayBeNull<string>
  left: boolean
  right: boolean
  up: boolean
  down: boolean
  selectedItem: MayBeNull<DashboardWidget>
  rightSiblingsId: string[]
  leftSiblingsId: string[]
  topSiblingsId: string[]
  bottomSiblingsId: string[]
  items: DashboardWidget[]
}

interface TooltipWrapperProps {
  condition: boolean
  wrapper: Function
  children: ReactNode
}

const TooltipWrapper = ({ condition, wrapper, children }: TooltipWrapperProps) =>
  condition ? wrapper(children) : children

export const DashboardEditMode = ({
  widgets,
  setWidgetsToSave,
  addRemoveWidgetsModalOpen,
  addRemoveWidgetsModalOnClose,
}: Props) => {
  const GRID_COLUMN_START = 1
  const GRID_COLUMN_END = 17
  const GRID_ROW_START = 1

  const findEmptySpace = (widgets: DashboardWidget[]) => {
    const MAX_ROW_VALUE = Math.max(...widgets.map(({ gridRowEnd }) => gridRowEnd), 1)
    const map: Record<number, Record<number, 1 | 0>> = {}

    for (let j = 1; j <= MAX_ROW_VALUE - 1; j++) {
      map[j] = {}
      const filtered = widgets.filter(w => j >= w.gridRowStart && j <= w.gridRowEnd - 1)

      for (let i = 1; i <= GRID_COLUMN_END - 1; i++) {
        const used = filtered.some(w => {
          return i >= w.gridColumnStart && i <= w.gridColumnEnd - 1
        })

        map[j][i] = used ? 1 : 0
      }
    }

    for (let k = 1; k <= Object.keys(map).length; k++) {
      for (let l = 1; l <= Object.keys(map[k]).length; l++) {
        if (map[k][l] === 0) {
          let row = k
          let column: number
          while (map[row] && map[row][l] === 0) {
            column = l + 1
            while (map[row][column] === 0 && column < GRID_COLUMN_END) {
              column++
            }
            row++
          }

          return {
            gridRowStart: k,
            gridRowEnd: row,
            gridColumnStart: l,
            gridColumnEnd: column!,
          }
        }
      }
    }
  }

  const fillEmptySpace = (widgets: DashboardWidget[]) => {
    const filled: DashboardWidget[] = [...widgets]

    let emptySpaceFilled = false

    while (!emptySpaceFilled) {
      const empty = findEmptySpace(filled.filter(widget => widget.enabled))
      const MIN_SIZE = 4

      if (empty) {
        const height = empty.gridRowEnd - empty.gridRowStart
        const width = empty.gridColumnEnd - empty.gridColumnStart

        const empties = []

        if ([width, height].some(size => size > MIN_SIZE)) {
          for (let i = empty.gridRowStart; i < empty.gridRowEnd; i += MIN_SIZE) {
            for (let j = empty.gridColumnStart; j < empty.gridColumnEnd; j += MIN_SIZE) {
              empties.push({
                gridRowStart: i,
                gridRowEnd: i + MIN_SIZE,
                gridColumnStart: j,
                gridColumnEnd: j + MIN_SIZE,
                enabled: true,
                id: `${Date.now()}${Math.random()}`,
                type: EmptyWidgetType.EMPTY,
                title: 'Empty',
              })
            }
          }
        } else {
          empties.push({
            gridRowStart: empty.gridRowStart,
            gridRowEnd: empty.gridRowEnd,
            gridColumnStart: empty.gridColumnStart,
            gridColumnEnd: empty.gridColumnEnd,
            enabled: true,
            id: `${Date.now()}${Math.random()}`,
            type: EmptyWidgetType.EMPTY,
            title: 'Empty',
          })
        }

        filled.push(...empties)
      } else {
        emptySpaceFilled = true
      }
    }

    return [...widgets, ...filled.filter(w => w.type === EmptyWidgetType.EMPTY)]
  }

  const [
    {
      selected,
      items,
      left,
      right,
      up,
      down,
      rightSiblingsId,
      leftSiblingsId,
      topSiblingsId,
      bottomSiblingsId,
      selectedItem,
    },
    setState,
  ] = useSetState<State>({
    selected: null,
    left: false,
    right: false,
    up: false,
    down: false,
    selectedItem: null,
    leftSiblingsId: [],
    rightSiblingsId: [],
    topSiblingsId: [],
    bottomSiblingsId: [],
    items: fillEmptySpace(widgets),
  })

  const gridRowEnd = Math.max(...widgets.map(({ gridRowEnd }) => gridRowEnd), 1)

  const verifyMultipleSiblings = (multipleSiblings: DashboardWidget[]) => {
    if (multipleSiblings.length < 2) {
      return []
    }

    const multipleSiblingArea =
      (multipleSiblings[0].gridColumnEnd - multipleSiblings[0].gridColumnStart) *
      (multipleSiblings[0].gridRowEnd - multipleSiblings[0].gridRowStart)

    const isMultipleSiblingsHasEqualArea = multipleSiblings.every(item => {
      const itemArea = (item.gridColumnEnd - item.gridColumnStart) * (item.gridRowEnd - item.gridRowStart)
      return itemArea === multipleSiblingArea
    })

    return isMultipleSiblingsHasEqualArea ? multipleSiblings.map(item => item.id) : []
  }

  const getLeftSiblingsId = (selectedItem: DashboardWidget) => {
    if (selectedItem.gridColumnStart === GRID_COLUMN_START) {
      return []
    }

    const singleSibling = items
      .filter(({ enabled }) => enabled)
      .find(item => {
        return (
          item.gridRowStart === selectedItem.gridRowStart &&
          item.gridRowEnd === selectedItem.gridRowEnd &&
          item.gridColumnEnd === selectedItem.gridColumnStart
        )
      })

    if (singleSibling?.id) {
      return [singleSibling.id]
    }

    const multipleSiblings = items
      .filter(({ enabled }) => enabled)
      .filter(item => {
        return (
          (item.gridRowStart === selectedItem.gridRowStart && item.gridColumnEnd === selectedItem.gridColumnStart) ||
          (item.gridRowEnd === selectedItem.gridRowEnd && item.gridColumnEnd === selectedItem.gridColumnStart)
        )
      })

    return verifyMultipleSiblings(multipleSiblings)
  }

  const getRightSiblingsId = (selectedItem: DashboardWidget) => {
    if (selectedItem.gridColumnEnd === GRID_COLUMN_END) {
      return []
    }

    const singleSibling = items
      .filter(({ enabled }) => enabled)
      .find(item => {
        return (
          item.gridRowStart === selectedItem.gridRowStart &&
          item.gridRowEnd === selectedItem.gridRowEnd &&
          item.gridColumnStart === selectedItem.gridColumnEnd
        )
      })

    if (singleSibling?.id) {
      return [singleSibling.id]
    }

    const multipleSiblings = items
      .filter(({ enabled }) => enabled)
      .filter(item => {
        return (
          (item.gridRowStart === selectedItem.gridRowStart && item.gridColumnStart === selectedItem.gridColumnEnd) ||
          (item.gridRowEnd === selectedItem.gridRowEnd && item.gridColumnStart === selectedItem.gridColumnEnd)
        )
      })

    return verifyMultipleSiblings(multipleSiblings)
  }

  const getTopSiblingsId = (selectedItem: DashboardWidget) => {
    if (selectedItem.gridRowStart === GRID_ROW_START) {
      return []
    }

    const singleSibling = items
      .filter(({ enabled }) => enabled)
      .find(item => {
        return (
          item.gridColumnStart === selectedItem.gridColumnStart &&
          item.gridColumnEnd === selectedItem.gridColumnEnd &&
          item.gridRowEnd === selectedItem.gridRowStart
        )
      })

    if (singleSibling?.id) {
      return [singleSibling.id]
    }

    const multipleSiblings = items
      .filter(({ enabled }) => enabled)
      .filter(item => {
        return (
          (item.gridColumnStart === selectedItem.gridColumnStart && item.gridRowEnd === selectedItem.gridRowStart) ||
          (item.gridColumnEnd === selectedItem.gridColumnEnd && item.gridRowEnd === selectedItem.gridRowStart)
        )
      })

    return verifyMultipleSiblings(multipleSiblings)
  }

  const getBottomSiblingsId = (selectedItem: DashboardWidget) => {
    const singleSibling = items
      .filter(({ enabled }) => enabled)
      .find(item => {
        return (
          item.gridColumnStart === selectedItem.gridColumnStart &&
          item.gridColumnEnd === selectedItem.gridColumnEnd &&
          item.gridRowStart === selectedItem.gridRowEnd
        )
      })

    if (singleSibling?.id) {
      return [singleSibling.id]
    }

    const multipleSiblings = items
      .filter(({ enabled }) => enabled)
      .filter(item => {
        return (
          (item.gridColumnStart === selectedItem.gridColumnStart && item.gridRowStart === selectedItem.gridRowEnd) ||
          (item.gridColumnEnd === selectedItem.gridColumnEnd && item.gridRowStart === selectedItem.gridRowEnd)
        )
      })

    return verifyMultipleSiblings(multipleSiblings)
  }

  const select = (id: string) => {
    const selectedItem = items.find(item => item.id === id)

    if (!selectedItem) {
      return
    }

    const leftSiblingsId = getLeftSiblingsId(selectedItem)

    const rightSiblingsId = getRightSiblingsId(selectedItem)

    const topSiblingsId = getTopSiblingsId(selectedItem)

    const bottomSiblingsId = getBottomSiblingsId(selectedItem)

    setState({
      selected: id,
      left: !!leftSiblingsId.length,
      right: !!rightSiblingsId.length,
      up: !!topSiblingsId.length,
      down: !!bottomSiblingsId.length,
      topSiblingsId,
      bottomSiblingsId,
      leftSiblingsId,
      rightSiblingsId,
      selectedItem,
    })
  }

  const copyItems = (selectedItemId: string, siblingItemsId: string[]) => {
    const itemsCopy = [...items]
    const selectedItemIndex = itemsCopy.findIndex(item => item.id === selectedItemId)
    const selectedItemCopy = { ...itemsCopy[selectedItemIndex] }
    itemsCopy.splice(selectedItemIndex, 1).pop()

    const siblingItemsCopy: DashboardWidget[] = []
    siblingItemsId.forEach(id => {
      const siblingItemIndex = itemsCopy.findIndex(item => item.id === id)
      siblingItemsCopy.push({ ...itemsCopy[siblingItemIndex] })
      itemsCopy.splice(siblingItemIndex, 1).pop()
    })

    return { itemsCopy, selectedItemCopy, siblingItemsCopy }
  }

  const moveLeft = (selectedItem: DashboardWidget) => {
    if (!leftSiblingsId.length) {
      return
    }

    const siblingItems = items.filter(item => leftSiblingsId.includes(item.id))

    const { itemsCopy, selectedItemCopy, siblingItemsCopy } = copyItems(selectedItem.id, leftSiblingsId)

    const selectedItemWidth = selectedItem.gridColumnEnd - selectedItem.gridColumnStart
    const siblingItemsWidth = siblingItems[0].gridColumnEnd - siblingItems[0].gridColumnStart

    selectedItemCopy.gridColumnStart = siblingItems[0].gridColumnStart
    selectedItemCopy.gridColumnEnd = selectedItemCopy.gridColumnStart + selectedItemWidth

    siblingItemsCopy.forEach(siblingItemCopy => {
      siblingItemCopy.gridColumnStart = selectedItem.gridColumnEnd - siblingItemsWidth
      siblingItemCopy.gridColumnEnd = selectedItem.gridColumnEnd
    })

    afterMove(itemsCopy, selectedItemCopy, siblingItemsCopy)
  }

  const moveRight = (selectedItem: DashboardWidget) => {
    if (!rightSiblingsId.length) {
      return
    }
    const siblingItems = items.filter(item => rightSiblingsId.includes(item.id))

    const { itemsCopy, selectedItemCopy, siblingItemsCopy } = copyItems(selectedItem.id, rightSiblingsId)

    const selectedItemWidth = selectedItem.gridColumnEnd - selectedItem.gridColumnStart
    const siblingItemWidth = siblingItems[0].gridColumnEnd - siblingItems[0].gridColumnStart

    selectedItemCopy.gridColumnEnd = siblingItems[0].gridColumnEnd
    selectedItemCopy.gridColumnStart = selectedItemCopy.gridColumnEnd - selectedItemWidth

    siblingItemsCopy.forEach(siblingItemCopy => {
      siblingItemCopy.gridColumnStart = selectedItem.gridColumnStart
      siblingItemCopy.gridColumnEnd = selectedItem.gridColumnStart + siblingItemWidth
    })

    afterMove(itemsCopy, selectedItemCopy, siblingItemsCopy)
  }

  const moveUp = (selectedItem: DashboardWidget) => {
    if (!topSiblingsId.length) {
      return
    }
    const siblingItems = items.filter(item => topSiblingsId.includes(item.id))

    const { itemsCopy, selectedItemCopy, siblingItemsCopy } = copyItems(selectedItem.id, topSiblingsId)

    const selectedItemHeight = selectedItem.gridRowEnd - selectedItem.gridRowStart
    const siblingItemHeight = siblingItems[0].gridRowEnd - siblingItems[0].gridRowStart

    selectedItemCopy.gridRowStart = siblingItems[0].gridRowStart
    selectedItemCopy.gridRowEnd = siblingItems[0].gridRowStart + selectedItemHeight

    siblingItemsCopy.forEach(siblingItemCopy => {
      siblingItemCopy.gridRowStart = selectedItem.gridRowEnd - siblingItemHeight
      siblingItemCopy.gridRowEnd = selectedItem.gridRowEnd
    })

    afterMove(itemsCopy, selectedItemCopy, siblingItemsCopy)
  }

  const moveDown = (selectedItem: DashboardWidget) => {
    if (!bottomSiblingsId.length) {
      return
    }

    const siblingItems = items.filter(item => bottomSiblingsId.includes(item.id))

    const { itemsCopy, selectedItemCopy, siblingItemsCopy } = copyItems(selectedItem.id, bottomSiblingsId)

    const selectedItemHeight = selectedItem.gridRowEnd - selectedItem.gridRowStart
    const siblingItemHeight = siblingItems[0].gridRowEnd - siblingItems[0].gridRowStart

    selectedItemCopy.gridRowStart = siblingItems[0].gridRowEnd - selectedItemHeight
    selectedItemCopy.gridRowEnd = siblingItems[0].gridRowEnd

    siblingItemsCopy.forEach(siblingItemCopy => {
      siblingItemCopy.gridRowStart = selectedItem.gridRowStart
      siblingItemCopy.gridRowEnd = selectedItem.gridRowStart + siblingItemHeight
    })

    afterMove(itemsCopy, selectedItemCopy, siblingItemsCopy)
  }

  const afterMove = (
    itemsCopy: DashboardWidget[],
    selectedItemCopy: DashboardWidget,
    siblingItemsCopy: DashboardWidget[],
  ) => {
    const itemsToSave = [...itemsCopy, selectedItemCopy, ...siblingItemsCopy]
    setState({ selected: null, items: itemsToSave })
    setWidgetsToSave(itemsToSave)
  }

  const removeWidget = (id: string) => {
    const widgetsCopy = [...items]
    const widgetToRemoveIndex = widgetsCopy.findIndex(widget => widget.id === id)
    const widgetCopy = { ...widgetsCopy[widgetToRemoveIndex] }
    widgetsCopy.splice(widgetToRemoveIndex, 1)

    widgetCopy.enabled = false

    const enabledWidgets = fillEmptySpace(
      alignWidgetsToLeft(
        widgetsCopy.filter(({ enabled, type }) => enabled && type !== EmptyWidgetType.EMPTY) as Widget[],
      ),
    )
    const disabledWidgets = [...widgetsCopy, widgetCopy].filter(({ enabled }) => !enabled)
    const widgetToSave = [...enabledWidgets, ...disabledWidgets]

    setState({ items: widgetToSave })
    setWidgetsToSave(widgetToSave)
  }

  const addWidget = (id: string) => {
    const widgetsCopy = [...items.filter(({ type }) => type !== EmptyWidgetType.EMPTY)]
    const widgetToRemoveIndex = widgetsCopy.findIndex(widget => widget.id === id)
    const widgetCopy = { ...widgetsCopy[widgetToRemoveIndex] }
    widgetsCopy.splice(widgetToRemoveIndex, 1)

    widgetCopy.enabled = true

    const enabledWidgets = widgetsCopy.filter(({ enabled }) => enabled)

    const widgetCopyHeight = widgetCopy.gridRowEnd - widgetCopy.gridRowStart
    const widgetCopyWidth = widgetCopy.gridColumnEnd - widgetCopy.gridColumnStart

    let added = false

    const widgetsToFindEmptySpace: DashboardWidget[] = [...enabledWidgets]

    do {
      const empty = findEmptySpace(widgetsToFindEmptySpace)
      if (empty) {
        const widthFits = empty.gridColumnEnd - empty.gridColumnStart >= widgetCopyWidth
        const heightFits = empty.gridRowEnd - empty.gridRowStart >= widgetCopyHeight
        if (widthFits && heightFits) {
          widgetCopy.gridRowStart = empty.gridRowStart
          widgetCopy.gridRowEnd = widgetCopy.gridRowStart + widgetCopyHeight
          widgetCopy.gridColumnStart = empty.gridColumnStart
          widgetCopy.gridColumnEnd = widgetCopy.gridColumnStart + widgetCopyWidth
          added = true
        } else {
          widgetsToFindEmptySpace.push({
            type: EmptyWidgetType.EMPTY,
            gridRowStart: empty.gridRowStart,
            gridRowEnd: empty.gridRowEnd,
            gridColumnStart: empty.gridColumnStart,
            gridColumnEnd: empty.gridColumnEnd,
            enabled: true,
            id: `${Date.now()}${Math.random()}`,
            title: 'Empty',
          })
        }
      } else {
        widgetCopy.gridRowStart = Math.max(...widgetsToFindEmptySpace.map(({ gridRowEnd }) => gridRowEnd), 1)
        widgetCopy.gridRowEnd = widgetCopy.gridRowStart + widgetCopyHeight
        widgetCopy.gridColumnStart = 1
        widgetCopy.gridColumnEnd = widgetCopy.gridColumnStart + widgetCopyWidth
        added = true
      }
    } while (!added)

    const widgetToSave = fillEmptySpace([...widgetsCopy, widgetCopy])

    setState({ items: widgetToSave })
    setWidgetsToSave(widgetToSave)
  }

  return (
    <>
      <AddRemoveWidgets
        addWidget={addWidget}
        removeWidget={removeWidget}
        widgets={items.filter(({ type }) => type !== EmptyWidgetType.EMPTY) as Widget[]}
        isOpen={addRemoveWidgetsModalOpen}
        onClose={addRemoveWidgetsModalOnClose}
      />
      {items
        .filter(({ enabled, type }) => enabled && type !== EmptyWidgetType.EMPTY)
        .map(({ id, gridRowEnd, gridRowStart, gridColumnEnd, gridColumnStart, name }) => (
          <div
            className={clsx(styles.item, { [styles.selected]: selected === id })}
            id={id}
            key={id}
            style={{ gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd }}
            onClick={() => {
              select(id)
            }}
          >
            <div className={styles.cover} />
            {selected === id ? (
              <>
                <div className={styles.left} data-testid="widget-move-left">
                  <TooltipWrapper
                    condition={left}
                    wrapper={(children: ReactNode) => (
                      <WppTooltip text="Move to left" config={{ placement: 'top' }}>
                        {children}
                      </WppTooltip>
                    )}
                  >
                    <WppFloatingButton
                      disabled={!left}
                      onClick={e => {
                        e.stopPropagation()
                        selectedItem && moveLeft(selectedItem)
                      }}
                    >
                      <WppIconChevron direction="left" />
                    </WppFloatingButton>
                  </TooltipWrapper>
                </div>
                <div className={styles.right} data-testid="widget-move-right">
                  <TooltipWrapper
                    condition={right}
                    wrapper={(children: ReactNode) => (
                      <WppTooltip text="Move to right" config={{ placement: 'top' }}>
                        {children}
                      </WppTooltip>
                    )}
                  >
                    <WppFloatingButton
                      disabled={!right}
                      onClick={e => {
                        e.stopPropagation()
                        selectedItem && moveRight(selectedItem)
                      }}
                    >
                      <WppIconChevron direction="right" size="s" />
                    </WppFloatingButton>
                  </TooltipWrapper>
                </div>
                <div className={styles.top} data-testid="widget-move-top">
                  <TooltipWrapper
                    condition={up}
                    wrapper={(children: ReactNode) => (
                      <WppTooltip text="Move to top" config={{ placement: 'top' }}>
                        {children}
                      </WppTooltip>
                    )}
                  >
                    <WppFloatingButton
                      disabled={!up}
                      onClick={e => {
                        e.stopPropagation()
                        selectedItem && moveUp(selectedItem)
                      }}
                    >
                      <WppIconChevron direction="top" size="s" />
                    </WppFloatingButton>
                  </TooltipWrapper>
                </div>
                <div className={styles.down} data-testid="widget-move-down">
                  <TooltipWrapper
                    condition={down}
                    wrapper={(children: ReactNode) => (
                      <WppTooltip text="Move to bottom" config={{ placement: 'top' }}>
                        {children}
                      </WppTooltip>
                    )}
                  >
                    <WppFloatingButton
                      disabled={!down}
                      onClick={e => {
                        e.stopPropagation()
                        selectedItem && moveDown(selectedItem)
                      }}
                    >
                      <WppIconChevron direction="down" size="s" />
                    </WppFloatingButton>
                  </TooltipWrapper>
                </div>
              </>
            ) : null}
            <RenderWidget type={name} isEditMode onRemove={() => removeWidget(id)} />
          </div>
        ))}
      <EmptyLayout columnsCount={GRID_COLUMN_END - GRID_COLUMN_START} rowsCount={gridRowEnd - GRID_ROW_START} />
    </>
  )
}
