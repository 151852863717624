// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fThIw{min-height:calc(100vh - var(--wpp-os-header-height));overflow-x:auto;background-color:var(--wpp-grey-color-100);--gap: 24px;--container-side-padding: 28px;--min-width: 1280px;--grid-width: calc( clamp(var(--min-width), 100vw, var(--wpp-os-content-max-width)) - (var(--container-side-padding) * 2) );--gap-count: 15;--columns-count: 16;--row-height: calc((var(--grid-width) - (var(--gap-count) * var(--gap))) / var(--columns-count))}", "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAGA,OACE,oDAAA,CACA,eAAA,CACA,0CAAA,CAEA,WAAA,CACA,8BAAA,CACA,mBAAA,CACA,2HAAA,CAGA,eAAA,CACA,mBAAA,CACA,gGAAA","sourcesContent":["$min-home-app-width: 1280;\n$min-home-app-height: 640;\n\n.root {\n  min-height: calc(100vh - var(--wpp-os-header-height));\n  overflow-x: auto;\n  background-color: var(--wpp-grey-color-100);\n\n  --gap: 24px;\n  --container-side-padding: 28px;\n  --min-width: 1280px;\n  --grid-width: calc(\n    clamp(var(--min-width), 100vw, var(--wpp-os-content-max-width)) - (var(--container-side-padding) * 2)\n  );\n  --gap-count: 15;\n  --columns-count: 16;\n  --row-height: calc((var(--grid-width) - (var(--gap-count) * var(--gap))) / var(--columns-count));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "fThIw"
};
export default ___CSS_LOADER_EXPORT___;
