// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B6qH1{width:100%;--wpp-list-item-width: 100%}", "",{"version":3,"sources":["webpack://./src/components/widgetListItem/WidgetListItem.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CAEA,2BAAA","sourcesContent":[".root {\n  width: 100%;\n\n  --wpp-list-item-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "B6qH1"
};
export default ___CSS_LOADER_EXPORT___;
