import { createApi } from 'api/common/createApi'
import { environment } from 'environment'

export const recentWorkApi = createApi({
  baseURL: environment.RECENT_WORK_API_URL,
})

export const newsApi = createApi({
  baseURL: environment.NEWS_API_URL,
})

export const projectsApi = createApi({
  baseURL: `${environment.PROJECTS_API_URL}api`,
})

export const facadeApi = createApi({
  baseURL: '/api',
})
