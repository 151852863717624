import { facadeApi } from 'api'
import { FavouriteLink } from 'types/favouriteLinks/favouriteLink'

interface Params {
  organisationsId: string
  userId: string
  id: string
}

export const updateFavouriteLinkLastUsedApi = ({ organisationsId, userId, id }: Params) =>
  facadeApi.put<FavouriteLink>(`/apps/${id}/user_settings/${userId}`, { az_account_id: organisationsId })
