import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { WorkflowTemplate } from 'types/project/workflowTemplate'

interface Params extends PaginationParams {
  tenantId: string
}

export const fetchWorkflowTemplatesApi = ({ tenantId, page = 1, itemsPerPage = 10 }: Params) =>
  facadeApi.get<PaginatedResponse<WorkflowTemplate>>('/workflow-templates', {
    params: { page, itemsPerPage },
    headers: {
      'X-Tenant-Id': tenantId,
    },
  })
