import { SortOrder } from 'api/common/types'

export const isString = (value: any): value is string => typeof value === 'string'

export const sortComparator =
  (order: SortOrder) =>
  <T extends string | number>(a: T, b: T) => {
    const value = isString(a) ? a.toLocaleLowerCase() : a
    const valueToCompare = isString(b) ? b.toLocaleLowerCase() : b

    if (value > valueToCompare) {
      return order === 'asc' ? 1 : -1
    }

    if (valueToCompare > value) {
      return order === 'asc' ? -1 : 1
    }

    return 0
  }

export const sortBy = <T, X extends string | number>(arr: T[], fn: (x: T) => X, order: SortOrder = 'asc') =>
  [...arr].sort((a, b) => sortComparator(order)(fn(a), fn(b)))
