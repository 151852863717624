import { MayBeNull, ValueOf } from 'types/utils'

export const FavouriteLinkStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DISABLED: 'DISABLED',
} as const

export interface FavouriteLink {
  az_account_id: string
  description: string
  last_used: MayBeNull<string>
  pinned: boolean
  url: string
  name: string
  status: ValueOf<typeof FavouriteLinkStatus>
  favourite: boolean
  id: string
  owner: string
  image_url: MayBeNull<string>
}
