import {
  WppActionButton,
  WppIconMore,
  WppIconExternalLink,
  WppIconAddApp,
  WppIconRemoveCircle,
  WppMenuContext,
  WppListItem,
} from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

interface Props {
  viewAllLink?: string
  addSourceLink?: string
  testId?: string
  isEditMode?: boolean
  viewAllAction?: () => void
  onRemove?: () => void
}

export const WidgetHeaderActions = ({
  viewAllLink,
  addSourceLink,
  testId,
  isEditMode,
  viewAllAction,
  onRemove,
}: Props) => {
  if (isEditMode) {
    return (
      <WppMenuContext
        slot="actions"
        data-testid={`${testId}-menu-context`}
        dropdownConfig={{
          appendTo: () => document.body,
          placement: 'bottom-end',
        }}
      >
        <WppActionButton variant="secondary" style={{ zIndex: 2 }} slot="trigger-element">
          <WppIconMore direction="horizontal" />
        </WppActionButton>

        {(viewAllLink || viewAllAction) && (
          <WppListItem
            data-testid={`${testId}-view-all`}
            {...(viewAllLink ? { linkConfig: { href: viewAllLink } } : {})}
            {...(viewAllAction ? { onWppChangeListItem: viewAllAction } : {})}
          >
            <WppIconExternalLink slot="left" />
            <span slot="label">View all</span>
          </WppListItem>
        )}

        {addSourceLink && (
          <WppListItem data-testid={`${testId}-add-source`} linkConfig={{ href: addSourceLink }}>
            <WppIconAddApp slot="left" />
            <span slot="label">Add a source</span>
          </WppListItem>
        )}

        {onRemove && (
          <WppListItem onWppChangeListItem={onRemove}>
            <WppIconRemoveCircle slot="left" />
            <span slot="label">Remove</span>
          </WppListItem>
        )}
      </WppMenuContext>
    )
  }

  return viewAllAction ? (
    <WppActionButton slot="actions" onClick={viewAllAction} variant="secondary" data-testid={`${testId}-view-all`}>
      View all
    </WppActionButton>
  ) : viewAllLink ? (
    <Link slot="actions" to={viewAllLink} data-testid={`${testId}-view-all`}>
      <WppActionButton variant="secondary">View all</WppActionButton>
    </Link>
  ) : null
}
