import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'

import { useFetchWorkflowTemplatesApi } from 'api/projects/queries/useFetchWorkflowTemplatesApi'
import { WidgetCard } from 'components/widgetCard/WidgetCard'
import { WidgetEmptyState } from 'components/widgetEmptyState/WidgetEmptyState'
import { WidgetHeaderActions } from 'components/widgetHeaderActions/WidgetHeaderActions'
import { WidgetList } from 'components/widgetList/WidgetList'
import { WidgetListItem } from 'components/widgetListItem/WidgetListItem'
import { useHasPermission } from 'hooks/useHasPermissions'
import { sortBy } from 'utils/common'

const WORKFLOW_TEMPLATES_MAX_COUNT = 100

const getAllTemplatesLink = () => '/orchestration/templates'
const getWorkflowTemplateLink = (templateId: string) => `/orchestration/templates/${templateId}`

interface Props {
  isEditMode?: boolean
  onRemove?: () => void
}

export const WorkflowTemplatesWidget = ({ isEditMode, onRemove }: Props) => {
  const { osContext } = useOs()
  const { hasAccessToManageWidgets } = useHasPermission()

  const {
    isLoading,
    error,
    data: workflowTemplates,
  } = useFetchWorkflowTemplatesApi({
    params: {
      tenantId: osContext.tenant.id,
      itemsPerPage: WORKFLOW_TEMPLATES_MAX_COUNT,
    },
  })

  const sortedWorkflowTemplates = useMemo(() => sortBy(workflowTemplates, ({ name }) => name), [workflowTemplates])

  const viewAllLink = error ? '' : getAllTemplatesLink()

  return (
    <WidgetCard data-testid="templates-widget">
      <WppTypography slot="header" type="m-strong">
        Project Templates
      </WppTypography>

      <WidgetHeaderActions viewAllLink={viewAllLink} testId="templates" isEditMode={isEditMode} onRemove={onRemove} />

      {!sortedWorkflowTemplates.length ? (
        <WidgetEmptyState
          isLoading={isLoading}
          text={hasAccessToManageWidgets ? 'Project templates will be displayed here' : 'No Templates Found'}
          actionButton={hasAccessToManageWidgets && { title: 'Add a template', link: '/orchestration/templates' }}
          {...(error && { errorText: 'Unable to display Templates' })}
        />
      ) : (
        <WidgetList
          items={sortedWorkflowTemplates}
          renderItem={({ id, name }) => {
            const href = getWorkflowTemplateLink(id)

            return (
              <WidgetListItem
                linkConfig={{
                  href,
                  onClick: e => {
                    e.preventDefault()
                    window.history.pushState({}, '', href)
                  },
                }}
              >
                <WppTypography slot="label" title={name} type="s-midi">
                  {name}
                </WppTypography>
              </WidgetListItem>
            )
          }}
        />
      )}
    </WidgetCard>
  )
}
