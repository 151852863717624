import { WppCard } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import styles from 'components/newsWidget/newsCard/NewsCard.module.scss'
import { SvgImageLoadError } from 'components/svg/SvgImageLoadError'
import { Truncate } from 'components/truncate/Truncate'
import { NewsPiece } from 'types/news/news'

interface Props {
  isBig?: boolean
  newsPiece: NewsPiece
}

export const NewsCard = ({ isBig = false, newsPiece }: Props) => {
  const [isImageLoadError, setIsImageLoadError] = useState(false)
  const { banner_image, title, guid } = newsPiece

  return (
    <Link to={`/news/${guid}`} className={styles.root} data-testid={`news-${guid}`}>
      <WppCard className={styles.card}>
        {isImageLoadError ? (
          <div className={styles.imagePlaceholder}>
            <SvgImageLoadError />
          </div>
        ) : (
          <img
            className={styles.image}
            src={banner_image}
            alt="Background"
            onError={() => {
              setIsImageLoadError(true)
            }}
          />
        )}
        <Truncate
          lines={3}
          type={isBig ? 'xl-heading' : 'm-body'}
          className={clsx(styles.text, {
            [styles.imageLoadErrorText]: isImageLoadError,
          })}
        >
          {title}
        </Truncate>
      </WppCard>
    </Link>
  )
}
