import clsx from 'clsx'

import styles from 'components/widgetList/WidgetList.module.scss'

type Props<T extends Record<string, any>> = {
  items: T[]
  renderItem: (item: T) => JSX.Element
} & JSX.IntrinsicElements['ul']

export function WidgetList<T extends Record<string, any>>({ items, renderItem, className, ...rest }: Props<T>) {
  return (
    <ul {...rest} className={clsx(styles.list, className)}>
      {items.map((item, i) => (
        <li key={i} className={styles.listItem}>
          {renderItem(item)}
        </li>
      ))}
    </ul>
  )
}
