import { facadeApi } from 'api'
import { PageAddUpdateDTO, Page } from 'types/widgets/page'

export interface Params {
  organizationId: string
  page: PageAddUpdateDTO
}

export const createPageApi = ({ organizationId, page }: Params) =>
  facadeApi.post<Page>('/pages', page, { headers: { 'organization-id': organizationId } })
