import { WppSpinner, WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import styles from 'components/widgetEmptyState/WidgetEmptyState.module.scss'

interface Props {
  isLoading: boolean
  text: string
  errorText?: string
  testId?: string
  actionButton?:
    | {
        title: string
        link: string
      }
    | false
}

export const WidgetEmptyState = ({ isLoading, text, errorText, testId, actionButton }: Props) => (
  <div className={styles.root}>
    {isLoading ? (
      <WppSpinner size="m" {...(testId && { dataTestid: `${testId}-loading` })} />
    ) : (
      <>
        <WppTypography
          type="s-midi"
          className={styles.textContainer}
          {...(testId && { dataTestid: `${testId}-empty-message` })}
        >
          {errorText || text}
        </WppTypography>
        {actionButton && (
          <Link
            slot="actions"
            to={actionButton.link}
            {...(testId && { dataTestid: `${testId}-action-btn` })}
            className={styles.actionButton}
          >
            <WppButton variant="secondary">{actionButton.title}</WppButton>
          </Link>
        )}
      </>
    )}
  </div>
)
