import { facadeApi } from 'api'
import { PageAddUpdateDTO } from 'types/widgets/page'

export interface Params {
  organizationId: string
  page: PageAddUpdateDTO
  pageId: string
}

export const updatePageApi = ({ organizationId, page, pageId }: Params) =>
  facadeApi.put<PageAddUpdateDTO>(`/pages/${pageId}`, page, { headers: { 'organization-id': organizationId } })
