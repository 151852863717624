import { recentWorkApi } from 'api'
import { RecentClient } from 'types/recentClients/recentClient'

export interface Params {
  parentId?: string
  userId: string
}

export interface RecentClientsResponse {
  data: RecentClient[]
}

export const fetchRecentClientsApi = ({ userId, parentId }: Params) =>
  recentWorkApi.get<RecentClientsResponse>(`/users/${userId}/clients`, {
    params: {
      lang: 'en-US',
      sort: 'name',
      parent_id: parentId,
    },
  })
