import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { Project, ProjectOwnership } from 'types/project/project'

export interface Params extends PaginationParams {
  tenantId: string
  ownership?: ProjectOwnership
}

export const fetchProjectsApi = ({ tenantId, ownership = ProjectOwnership.ALL, page = 1, itemsPerPage = 10 }: Params) =>
  facadeApi.post<PaginatedResponse<Project>>(
    '/projects/search',
    {
      'filter[ownership]': ownership,
    },
    {
      params: {
        page,
        itemsPerPage,
      },
      headers: {
        'X-Tenant-Id': tenantId,
      },
    },
  )
