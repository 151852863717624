import { FavouriteLinksWidget } from 'components/favouriteLinksWidget/FavouriteLinksWidget'
import { NewsWidget } from 'components/newsWidget/NewsWidget'
import { ProjectsWidget } from 'components/projectsWidget/ProjectsWidget'
import { RecentClientsWidget } from 'components/recentClientsWidget/RecentClientsWidget'
import { RecentWorkWidget } from 'components/recentWorkWidget/RecentWorkWidget'
import { WorkflowTemplatesWidget } from 'components/workflowTemplatesWidget/WorkflowTemplatesWidget'
import { SystemWidget } from 'types/widgets/widget'

interface Props {
  type: SystemWidget | undefined
  isEditMode?: boolean
  onRemove?: () => void
}

export const RenderWidget = ({ type, isEditMode, onRemove }: Props) => {
  switch (type) {
    case SystemWidget.NEWS:
      return <NewsWidget isEditMode={isEditMode} onRemove={onRemove} />
    case SystemWidget.FAVOURITE_LINKS:
      return <FavouriteLinksWidget isEditMode={isEditMode} onRemove={onRemove} />
    case SystemWidget.RECENT_WORK:
      return <RecentWorkWidget isEditMode={isEditMode} onRemove={onRemove} />
    case SystemWidget.RECENT_CLIENTS:
      return <RecentClientsWidget isEditMode={isEditMode} onRemove={onRemove} />
    case SystemWidget.PROJECTS:
      return <ProjectsWidget isEditMode={isEditMode} onRemove={onRemove} />
    case SystemWidget.PROJECT_TEMPLATES:
      return <WorkflowTemplatesWidget isEditMode={isEditMode} onRemove={onRemove} />
    default:
      return null
  }
}
