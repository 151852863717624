import { ToastState } from '@platform-ui-kit/components-library'
import { WppToastContainer } from '@platform-ui-kit/components-library-react'
import { PropsWithChildren, createContext, useRef, useCallback } from 'react'

export const ToastContext = createContext<{
  enqueueToast: (config: ToastState) => Promise<void> | undefined
  dequeueToast: (id: string) => Promise<void> | undefined
}>(null!)

export const ToastProvider = ({ children }: PropsWithChildren<{}>) => {
  const ref = useRef<HTMLWppToastContainerElement>(null)

  const enqueueToast = useCallback(
    ({ duration = 4000, ...config }: ToastState) => ref.current?.addToast({ duration, ...config }),
    [],
  )

  const dequeueToast = useCallback((id: string) => ref.current?.hideToast(id), [])

  return (
    <ToastContext.Provider
      value={{
        enqueueToast,
        dequeueToast,
      }}
    >
      <WppToastContainer ref={ref} maxToastsToDisplay={5} data-testid="toast-container" />
      {children}
    </ToastContext.Provider>
  )
}
