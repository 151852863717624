import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'

import { useProjectsApi } from 'api/projects/queries/useProjectsApi'
import { ProjectTypeTag } from 'components/projectsWidget/projectTypeTag/ProjectTypeTag'
import { WidgetCard } from 'components/widgetCard/WidgetCard'
import { WidgetEmptyState } from 'components/widgetEmptyState/WidgetEmptyState'
import { WidgetHeaderActions } from 'components/widgetHeaderActions/WidgetHeaderActions'
import { WidgetList } from 'components/widgetList/WidgetList'
import { WidgetListItem } from 'components/widgetListItem/WidgetListItem'
import { useHasPermission } from 'hooks/useHasPermissions'
import { ProjectOwnership } from 'types/project/project'
import { sortBy } from 'utils/common'

const PROJECTS_MAX_COUNT = 100

const getAllProjectsLink = () => '/orchestration/dashboard'
const getProjectLink = (projectId: string) => `/orchestration/project/${projectId}/overview`

interface Props {
  isEditMode?: boolean
  onRemove?: () => void
}

export const ProjectsWidget = ({ isEditMode, onRemove }: Props) => {
  const { osContext } = useOs()
  const { hasAccessToManageWidgets } = useHasPermission()

  const {
    isLoading,
    error,
    data: projects,
  } = useProjectsApi({
    params: {
      tenantId: osContext.tenant.id,
      itemsPerPage: PROJECTS_MAX_COUNT,
      ownership: ProjectOwnership.MEMBER,
    },
  })

  const sortedProjects = useMemo(() => sortBy(projects, ({ name }) => name), [projects])

  const viewAllLink = error ? '' : getAllProjectsLink()

  return (
    <WidgetCard data-testid="projects-widget">
      <WppTypography slot="header" type="m-strong">
        Projects
      </WppTypography>

      <WidgetHeaderActions viewAllLink={viewAllLink} testId="projects" isEditMode={isEditMode} onRemove={onRemove} />
      {!sortedProjects.length ? (
        <WidgetEmptyState
          isLoading={isLoading}
          text={hasAccessToManageWidgets ? "Member's projects will be displayed here" : 'No Projects found'}
          {...(error && { errorText: 'Unable to display Projects' })}
          actionButton={hasAccessToManageWidgets && { title: 'Add a project', link: '/orchestration/projects' }}
        />
      ) : (
        <WidgetList
          items={sortedProjects}
          renderItem={({ id, name, type }) => {
            const href = getProjectLink(id)

            return (
              <WidgetListItem
                linkConfig={{
                  href,
                  onClick: e => {
                    e.preventDefault()
                    window.history.pushState({}, '', href)
                  },
                }}
              >
                <WppTypography slot="label" title={name} type="s-midi">
                  {name}
                </WppTypography>
                <ProjectTypeTag slot="right" type={type} />
              </WidgetListItem>
            )
          }}
        />
      )}
    </WidgetCard>
  )
}
