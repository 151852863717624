import { WppCard } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'

import { useNewsApi } from 'api/news/queries/useNewsApi'
import { NewsCard } from 'components/newsWidget/newsCard/NewsCard'
import styles from 'components/newsWidget/NewsWidget.module.scss'
import { WidgetEmptyState } from 'components/widgetEmptyState/WidgetEmptyState'
import { WidgetHeaderActions } from 'components/widgetHeaderActions/WidgetHeaderActions'
import { useHasPermission } from 'hooks/useHasPermissions'

const maxNewsLength = 5

interface Props {
  isEditMode?: boolean
  onRemove?: () => void
}

export const NewsWidget = ({ isEditMode, onRemove }: Props) => {
  const { osContext } = useOs()
  const { tenant } = osContext
  const { hasAccessToManageWidgets } = useHasPermission()

  const {
    isLoading,
    error,
    data: news,
  } = useNewsApi({
    params: {
      tenantId: tenant.azMeta.organizationsId,
    },
  })

  const viewAllLink = error ? '' : '/news'

  if (!news.length) {
    return (
      <WppCard className={styles.card} data-testid="news-widget">
        <WidgetHeaderActions viewAllLink={viewAllLink} testId="news" isEditMode={isEditMode} onRemove={onRemove} />
        <WidgetEmptyState
          isLoading={isLoading}
          text={
            hasAccessToManageWidgets ? 'OS News will be displayed here once you add a news source' : 'No News found'
          }
          testId="news"
          actionButton={hasAccessToManageWidgets && { title: 'Add a source', link: '/news/settings' }}
          {...(error && { errorText: 'Unable to display News' })}
        />
      </WppCard>
    )
  }

  return (
    <div className={styles.gridWrapper} data-testid="news-widget">
      {isEditMode && (
        <div className={styles.widgetHeader}>
          <WidgetHeaderActions
            viewAllLink="/news"
            addSourceLink="/news/settings"
            testId="news"
            isEditMode
            onRemove={onRemove}
          />
        </div>
      )}
      <div className={clsx(styles.grid, styles[`length-${Math.min(news.length, maxNewsLength)}`])}>
        {news.slice(0, maxNewsLength).map((newsPiece, index) => (
          <NewsCard
            key={newsPiece.guid}
            isBig={news.length !== 2 && index === 0}
            newsPiece={newsPiece}
            data-testid="news-list"
          />
        ))}
      </div>
    </div>
  )
}
